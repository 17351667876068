import React from 'react';
import RegistroInventarioContent from './RegistroInventarioContent';
import { RegistroInventarioContextProvider } from './RegistroInventarioContext';

export const RegistroDeInventario: React.FC = () => {
  return (
    <RegistroInventarioContextProvider>
      <RegistroInventarioContent />
    </RegistroInventarioContextProvider>
  );
};
