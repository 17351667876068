import { HeaderPdfProps } from '../protocols/index';

export const headerPDF: HeaderPdfProps[] = [
  { key: 'val_icms', headerName: 'Alíquota ICMS', hide: false },
  {
    key: 'val_tot_custo_sem_imposto',
    headerName: 'Custo S. Imp.',
    hide: false,
  },
  { key: 'val_tot_custo_cims_pc', headerName: 'Total C. Imp.', hide: false },
  { key: 'val_tot_icms_recup', headerName: 'ICMS Recuperado', hide: false },
  {
    key: 'val_tot_pis_cof_recup',
    headerName: 'PIS/COFINS Recup.',
    hide: false,
  },
];
