import api from '~/services/api';

const registroInventarioApi = {
  getRegistros: async (cod_loja: number, dta_diario: string): Promise<any> => {
    const request = await api.get(
      `/registro-inventario/${cod_loja}/${dta_diario}`,
    );
    const { success, message, data } = request.data;

    return {
      success,
      data,
      message,
    };
  },
  getInfoLoja: async (cod_loja: number): Promise<any> => {
    const request = await api.get(`/registro-inventario/info-loja/${cod_loja}`);
    const { success, message, data } = request.data;

    return {
      success,
      data,
      message,
    };
  },
};

export default registroInventarioApi;
