import styled from 'styled-components';

export const TableContainer = styled.div`
  height: 100%;
  height: 380px;
  margin-top: 1rem;

  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #671ea7;
  }
`;

export const ColumnSeparator = styled.div`
  right: -12px;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  min-height: 56px;
  opacity: 1;
  top: 0;
  z-index: 999;

  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }

  .MuiSvgIcon-root {
    width: 1.1em;
  }
`;
