/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { moneyFormat } from '~/utils/functions';
import { headerPDF } from '../utils/headerPDF';
import { format, getDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class PDFGenerator {
  private docDefinition: any;
  private total: { [key: string]: number } = {};
  private dummy: boolean;

  constructor(
    private items: any[],
    private dates: { dta_fim: any },
    private loja: any,
  ) {
    this.docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      pageMargins: 7,
      content: [],
    };
    this.calculateTotals();

    this.dummy = false;
  }

  private static getPageWidth() {
    const pageSize = { width: 690, height: 595 };
    return pageSize.width;
  }
  private static getVisibleHeaders(): any[] {
    return headerPDF.filter((header) => !header.hide);
  }
  private static getValColumns(headers: any[]): string[] {
    return headers
      .map((header) => header.key)
      .filter((field) => field.startsWith('val_'));
  }
  private static initializeTotals(columns: string[]): {
    [key: string]: number;
  } {
    const totals: { [key: string]: number } = {};
    columns.forEach((column) => {
      totals[column] = 0;
    });
    return totals;
  }

  private calculateTotals() {
    const visibleHeaders = PDFGenerator.getVisibleHeaders();
    const valColumns = PDFGenerator.getValColumns(visibleHeaders);

    this.total = PDFGenerator.initializeTotals(valColumns);

    this.items.forEach((item: any) => {
      valColumns.forEach((column) => {
        const value = parseFloat(item[column] as string) || 0;
        this.total[column] += value;
      });
    });
  }

  private addInfoLoja() {
    const { loja } = this;

    const diasDaSemana = [
      'DOMINGO',
      'SEGUNDA-FEIRA',
      'TERÇA-FEIRA',
      'QUARTA-FEIRA',
      'QUINTA-FEIRA',
      'SEXTA-FEIRA',
      'SÁBADO',
    ];
    const dataAtual = new Date();
    const diaSemana = diasDaSemana[getDay(dataAtual)].toUpperCase();
    const dataFormatada = format(
      dataAtual,
      "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
      { locale: ptBR },
    ).toUpperCase();

    const lojaInfo = [
      { text: loja.des_fantasia, bold: true, fontSize: 12 },
      {
        text: `${loja.des_logradouro} - Nº: ${loja.num_endereco} - ${loja.des_bairro} CEP: ${loja.num_cep} ${loja.des_cidade}`,
        fontSize: 10,
      },
      { text: `CNPJ: ${loja.num_cnpj}`, fontSize: 10 },
      { text: `${diaSemana}, ${dataFormatada}`, fontSize: 10 },
    ];

    this.docDefinition.content.push({ stack: lojaInfo, margin: [0, 0, 0, 10] });
  }

  private addHeader() {
    const visibleHeaders = PDFGenerator.getVisibleHeaders();
    const columnCount = visibleHeaders.length;

    // Define larguras fixas para as colunas
    const pageWidth = PDFGenerator.getPageWidth();
    const columnWidth = pageWidth / columnCount;
    const columnWidths = Array(columnCount).fill(columnWidth);
    // Ajustes específicos para algumas colunas
    const adjustedColumnWidths = columnWidths.map((width, idx) => {
      switch (idx) {
        case 0:
          return width + 19;
        case 1:
          return width + 19;
        case 2:
          return width + 19;
        case 3:
          return width + 19;
        case 4:
          return width + 19;
        default:
          return width + 19;
      }
    });

    const headerCells = visibleHeaders.map((item, idx) => ({
      text: item.headerName,
      alignment: 'right',
      fontSize: 7.1,
      border: [false, true, false, true],
      margin: 0,
    }));

    const header = {
      table: {
        widths: adjustedColumnWidths,
        headerRows: 1,
        dontBreakRows: true,
        keepWithHeaderRows: 1,
        body: [headerCells],
      },
      layout: {
        defaultBorder: false,
      },
      margin: 0,
    };

    this.docDefinition.content.push(header);
  }

  private createCell(value: any, idx: number) {
    this.dummy = false;
    return {
      text:
        value === '' || value === null || value === undefined
          ? '-'
          : moneyFormat(value.toFixed(2)),
      alignment: 'right',
      fontSize: 7,
      bold: !!(idx === 0 || idx === 1),
      border: [false, false, false, false],
      margin: 0,
    };
  }
  private generateRows(items: any[], visibleHeaders: any[]) {
    return items.map((item) => {
      return visibleHeaders.map((header, idx) => {
        const key = header.key as keyof any;
        const value = item[key];
        return this.createCell(value, idx);
      });
    });
  }

  private addContent() {
    const { items } = this;
    const visibleHeaders = headerPDF.filter((header) => !header.hide);
    const columnCount = visibleHeaders.length;

    // Define larguras fixas para as colunas
    const pageWidth = PDFGenerator.getPageWidth();
    const columnWidth = pageWidth / columnCount;
    const columnWidths = Array(columnCount).fill(columnWidth);
    const newColumnWidths: number[] = columnWidths.map((width) => width - 0.5);
    newColumnWidths[0] += 19;
    newColumnWidths[1] += 19;
    newColumnWidths[2] += 19;
    newColumnWidths[3] += 19;
    newColumnWidths[4] += 19;

    // Gerar conteúdo da tabela
    const registrosBody = this.generateRows(items, visibleHeaders);

    const content = [
      {
        table: {
          widths: newColumnWidths,
          dontBreakRows: true,
          body: [...registrosBody],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 2,
          paddingBottom: () => 2,
        },
      },
    ];

    this.docDefinition.content.push(...content);
  }

  private addSubTotal() {
    const visibleHeaders = headerPDF.filter((header) => !header.hide);
    const columnCount = visibleHeaders.length;
    const pageWidth = PDFGenerator.getPageWidth();
    const columnWidth = pageWidth / columnCount;
    const columnWidths = Array(columnCount).fill(columnWidth);
    columnWidths[0] += 19;
    columnWidths[1] += 19;
    columnWidths[2] += 19;
    columnWidths[3] += 19;
    columnWidths[4] += 19;

    const saidaTotalsRow = visibleHeaders.map((header, idx) => {
      const key = header.key as string;
      if (idx === 0) {
        return {
          text: idx === 0 ? 'Sub.Total' : '',
          alignment: idx === 0 || idx === 14 ? 'left' : 'right',
          fontSize: 7,
          bold: true,
          border: [false, false, false, false],
          fillColor: '#bfbfbf', // Cor de fundo para a linha de totais
          margin: 0,
        };
      }

      return {
        text: `${
          this.total[key] === 0
            ? ''
            : moneyFormat(this.total[key].toFixed(2)) || ''
        }`,
        alignment: 'right',
        fontSize: 7,
        border: [false, false, false, false],
        fillColor: '#bfbfbf', // Cor de fundo para a linha de totais
        margin: 0,
      };
    });

    const content = [
      {
        table: {
          widths: columnWidths,
          dontBreakRows: true,
          body: [saidaTotalsRow],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 2,
          paddingBottom: () => 2,
        },
      },
    ];

    this.docDefinition.content.push(...content);
  }

  private addFooter() {
    const endDate = this.dates.dta_fim;
    const companyName = 'Konvix Tecnologia - www.konvix.com.br';

    const footerText = `Registro de Inventário - Período (${endDate})\n${companyName}`;

    const footerContent = {
      text: footerText,
      alignment: 'right',
      fontSize: 8,
      margin: 0,
      marginLeft: 80,
      bold: true,
    };

    const footerTable = {
      table: {
        dontBreakRows: true,
        body: [[footerContent]],
      },
      margin: [565, 5, 0, 0],
      layout: 'noBorders',
    };

    this.docDefinition.content.push(footerTable);
  }

  public generatePDF(): void {
    try {
      this.addInfoLoja();
      this.addHeader();
      this.addContent();
      this.addSubTotal();
      this.addFooter();

      const pdfDocGenerator = pdfMake.createPdf(this.docDefinition);

      pdfDocGenerator.open(); // Isso abre o PDF em uma nova aba/janela
    } catch (error) {
      console.log('Erro ao gerar o PDF:', error);
    }
  }
}

export default PDFGenerator;
