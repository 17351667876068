import styled from 'styled-components';

export const Container = styled.div`
  .buttonContainer {
    display: flex;
    gap: 0.3125rem;
    margin-top: 30px;
    .button {
      min-width: 50px !important;
      color: #fff;
    }
  }

  @media screen and (max-width: 992px) {
    .colLoja {
      width: 73%;
    }
    .colDataContainer {
      width: 25%;
    }

    .colContainer {
      width: 100%;
    }
  }
`;

export const ContainerDate = styled.div`
  position: relative;
  margin-top: 10px;
`;
