import styled from 'styled-components';

interface ContainerCalendarProps {
  showCalendar: boolean;
}

const getVisibilityCalendar = (statusPdv: boolean) => {
  if (statusPdv) return 'visible';
  return 'hidden';
};

export const InputContainer = styled.div<ContainerCalendarProps>`
  .is-invalid {
    background-image: none;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    border-bottom: 2px solid #cf1919;
    &:focus {
      box-shadow: none;
    }
  }

  .containerIconCalendar {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .react-datepicker {
    visibility: ${(props) => getVisibilityCalendar(props.showCalendar)};
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__month-container {
    min-width: 220px;
  }

  .form-label {
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    font-weight: 600;
    line-height: 1.3333333333333333;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    margin-bottom: 4px;
  }

  input {
    width: 100%;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    background-color: #fff;
    font-size: 0.875rem;
    padding: 8px 6px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    &:focus {
      border-color: var(--ds-border-focus, #4c9aff);
      outline: none;
      box-shadow: none;
    }
  }

  input[type='number'],
  input[type='weight'] {
    text-align: right;
    padding: 8px 6px;
  }

  input[type='date'] {
    padding: 8px 6px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin-left: 0.5rem;
  }

  input:disabled {
    cursor: no-drop;
  }

  .react-datepicker-popper {
    z-index: 99999;
  }
`;
