import React, { MouseEventHandler, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Loja from '~/components/Loja';

import { useRegistroInventario } from '../../RegistroInventarioContext';
import FormActions from './FormActions';

import { ContainerDate, Container } from './styles';
import { InputMesAno } from '../InputMesAno';
import useAuth from '~/hooks/useAuth';

export const FormFiltros: React.FC = () => {
  const { user } = useAuth();
  const {
    disabledFields,
    formTela: {
      setValue,

      formState: { errors },
    },
    setCodLoja,
    codLoja,
    setDateValue,
    dateValue,
    dateIsInvalid,
    setDateIsInvalid,
    init,
  } = useRegistroInventario();

  useEffect(() => {
    setDateValue(new Date());
    setValue('date', new Date());

    setCodLoja(Number(user?.loja));
  }, []);

  return (
    <Container>
      <Row>
        <Col
          sm={12}
          md={5}
          className="colLoja"
          style={{ marginTop: '10px', zIndex: 99 }}
        >
          <Loja
            isDisabled={disabledFields}
            onChange={(val) => {
              setCodLoja(Number(val));
            }}
            selectedLoja={codLoja}
            IsInvalid={!!errors.cod_loja}
          />
        </Col>
        <Col
          sm={12}
          md={2}
          className="colDataContainer"
          style={{
            paddingRight: '0px',
            paddingLeft: '0px',
          }}
        >
          <ContainerDate>
            <InputMesAno
              dateValue={dateValue}
              disabledFields={disabledFields}
              setDateValue={setDateValue}
              setValue={setValue}
              isInvalid={dateIsInvalid}
              setIsInvalid={setDateIsInvalid}
              init={init}
            />
          </ContainerDate>
        </Col>

        <Col sm={12} md={5} className="colContainer">
          <FormActions />
        </Col>
      </Row>
    </Container>
  );
};
