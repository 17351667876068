export interface FormReceita {
  cod_receita: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_receita: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_gluten: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_formula: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export const formReceitaBlank: FormReceita = {
  cod_receita: {
    value: undefined,
    isRequired: false,
    isInvalid: false,
  },
  des_receita: {
    value: '',
    isRequired: true,
    isInvalid: true,
  },
  flg_gluten: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  des_formula: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
};
