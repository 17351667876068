import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

import { yupResolver } from '@hookform/resolvers/yup';

import { UseFormProps } from './protocols';

import { schema } from './validations';
import { LojaContext } from '~/context/loja';
import PDFGenerator from './classes/PDFGenerator';
import registroInventarioApi from './services';
import { toast } from 'react-toastify';

type RegistroInventarioContextData = {
  formTela: UseFormProps;
  setLoader: (val: boolean) => void;
  loader: boolean;
  isFetchingTable: boolean;
  codLoja: number;
  setCodLoja: (val: number) => void;
  handleRegistros: (val: any) => void;
  handleClearFormFilters: () => void;
  handleDisabledFields: (disabled: boolean) => void;
  handleIsFetchingTable: (loading: boolean) => void;
  disabledFields: boolean;
  registros: any;
  columnsRegistroInventario: any;
  handlePrint: () => void;

  dateValue: Date;
  setDateValue: (val: Date) => void;

  dateIsInvalid: boolean;
  setDateIsInvalid: (val: boolean) => void;
  init: boolean;
  setInit: (val: boolean) => void;
};

export const RegistroInventarioContext = createContext(
  {} as RegistroInventarioContextData,
);

interface RegistroInventarioContextProviderProps {
  children: ReactNode;
}

export function RegistroInventarioContextProvider({
  children,
}: RegistroInventarioContextProviderProps): JSX.Element {
  const columnsRegistroInventario = [
    {
      field: 'val_icms',
      headerName: 'Alíquota ICMS',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      width: '15%',
      format: 'money',
      totalizador: false,
      hideRightSeparator: false,
    },
    {
      field: 'val_tot_custo_sem_imposto',
      headerName: 'Custo S. Imp.',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      width: '25%',
      format: 'money',
      hideRightSeparator: false,
    },
    {
      field: 'val_tot_custo_cims_pc',
      headerName: 'Total C. Imp.',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      width: '20%',
      hideRightSeparator: false,
      format: 'money',
    },
    {
      field: 'val_tot_icms_recup',
      headerName: 'ICMS Recuperado',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      width: '20%',
      hideRightSeparator: false,
      format: 'money',
    },
    {
      field: 'val_tot_pis_cof_recup',
      headerName: 'PIS/COFINS Recup.',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      width: '20%',
      hideRightSeparator: true,
      format: 'money',
    },
  ];

  const {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    clearErrors,
    setError,
    reset,
  } = useForm({ resolver: yupResolver(schema), reValidateMode: 'onBlur' });

  const formTela: UseFormProps = {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    clearErrors,
    setError,
    reset,
  };

  const { loja } = useContext(LojaContext);

  const [loader, setLoader] = useState<boolean>(false);
  const [isFetchingTable, setIsFetchingTable] = useState<boolean>(false);
  const [codLoja, setCodLoja] = useState<number>(loja.cod_loja);
  const [registros, setRegistros] = useState<number[]>([]);
  const [disabledFields, setDisabledFields] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<Date>(new Date());
  const [dateIsInvalid, setDateIsInvalid] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    setCodLoja(loja.cod_loja);
  }, []);

  const handleRegistros = (val: any) => {
    setRegistros(val);
  };
  const handleDisabledFields = (disabled: boolean) => {
    setDisabledFields(disabled);
  };
  const handleIsFetchingTable = (loading: boolean) => {
    setIsFetchingTable(loading);
  };

  const handleClearFormFilters = () => {
    reset();

    setDateValue(new Date());
    setValue('date', new Date());
    setRegistros([]);
  };

  const handlePrint = async () => {
    const infoLoja = await registroInventarioApi.getInfoLoja(codLoja);

    if (!infoLoja.success) {
      return toast.error(
        'Houve um erro ao tentar imprimir relatorio. Favor contatar o suporte.',
      );
    }
    const { date } = getValues();

    const mes = date.getMonth() + 1;
    const ano = date.getFullYear();
    const dta_diario = format(new Date(ano, mes, 0), 'dd/MM/yyyy');
    if (registros.length > 0 && dta_diario && infoLoja.data.length > 0) {
      const pdfGenerator = new PDFGenerator(
        registros,
        {
          dta_fim: dta_diario,
        },
        infoLoja.data[0],
      );
      pdfGenerator.generatePDF();
    }
  };

  return (
    <RegistroInventarioContext.Provider
      value={{
        formTela,
        setLoader,
        loader,
        isFetchingTable,
        codLoja,
        setCodLoja,
        handleRegistros,
        registros,
        handleClearFormFilters,
        disabledFields,
        handleDisabledFields,
        handleIsFetchingTable,
        columnsRegistroInventario,
        handlePrint,

        dateValue,
        setDateValue,
        dateIsInvalid,
        setDateIsInvalid,
        init,
        setInit,
      }}
    >
      {children}
    </RegistroInventarioContext.Provider>
  );
}

export const useRegistroInventario = (): RegistroInventarioContextData => {
  return useContext(RegistroInventarioContext);
};
