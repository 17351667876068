import React from 'react';

import { useRegistroInventario } from '../../../RegistroInventarioContext';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { FaEraser } from 'react-icons/fa';
import registroInventarioApi from '~/pages/RegistroDeInventario/services';
import { toast } from 'react-toastify';

const FormActions: React.FC = () => {
  const {
    handleRegistros,
    codLoja,
    handleClearFormFilters,
    handleDisabledFields,
    disabledFields,
    formTela: { handleSubmit, setError, clearErrors },
    handleIsFetchingTable,
    isFetchingTable,
    dateIsInvalid,
    setInit,
    setDateValue,
  } = useRegistroInventario();

  const handleSearch = handleSubmit(async (data) => {
    const mes = data.date.getMonth() + 1;
    const ano = data.date.getFullYear();

    if (dateIsInvalid) {
      setInit(true);
      setDateValue(new Date());
      toast.warning(
        'Data inválida: Por favor, informe o mês com 2 caracteres (MM) e o ano com 4 caracteres (AAAA).',
      );
      return;
    }

    const anoAtual = new Date();
    const mesAtual = new Date();

    if (ano === anoAtual.getFullYear() && mes > mesAtual.getMonth() + 1) {
      return toast.warning('Pesquisa não pode ser feita com meses futuros!');
    }

    if (!codLoja) {
      setError('cod_loja', { type: 'error' });
      return;
    }
    handleIsFetchingTable(true);
    handleDisabledFields(true);

    const dta_diario = new Date(ano, mes, 0);

    try {
      const registros = await registroInventarioApi.getRegistros(
        Number(codLoja),
        dta_diario.toISOString().split('T')[0],
      );
      if (registros.success) {
        if (registros.data.length > 0) {
          handleRegistros(registros.data);
        } else {
          handleRegistros([]);
          handleDisabledFields(false);
        }
      } else {
        handleDisabledFields(false);
        toast.error(
          'Houve um erro ao formatar os dados da busca. Favor contatar o suporte.',
        );
      }
      setInit(false);
      handleIsFetchingTable(false);
    } catch (error) {
      handleIsFetchingTable(false);
      handleDisabledFields(false);
    }
  });

  const clearFields = () => {
    clearErrors();
    handleClearFormFilters();
    setInit(false);
  };
  const handleCancelFilters = () => {
    handleDisabledFields(false);
    handleRegistros([]);
    clearErrors();
    setInit(false);
  };

  return (
    <div className="buttonContainer">
      <CustomButtonNovo
        onClick={handleSearch}
        disabled={disabledFields}
        label="Pesquisar"
        width="33%"
        icon={IoIosSearch}
        className="button"
      />
      <CustomButtonNovo
        onClick={handleCancelFilters}
        disabled={!disabledFields || isFetchingTable}
        label="Cancelar"
        variant="cancel"
        width="33%"
        icon={MdOutlineCancel}
        className="button"
      />
      <CustomButtonNovo
        onClick={clearFields}
        disabled={disabledFields}
        label="Limpar"
        variant="clear"
        width="33%"
        icon={FaEraser}
        className="button"
      />
    </div>
  );
};

export default FormActions;
