import React from 'react';

import { FormFiltros } from './components/FormFiltros';

import { Container, SeparatorLine } from './styles';
import FooterDefault from '~/components/FooterDefault';
import DefaultLoader from '~/components/DefaultLoader';
import { useRegistroInventario } from './RegistroInventarioContext';
import CustomDataGrid from './components/CustomDataGrid';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';

import { AiOutlinePrinter } from 'react-icons/ai';

const RegistroInventario: React.FC = () => {
  const {
    loader,
    columnsRegistroInventario,
    handlePrint,
    disabledFields,
    registros,
    isFetchingTable,
  } = useRegistroInventario();

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }
  return (
    <Container>
      <h3>Registro de Inventário</h3>
      <SeparatorLine />
      <FormFiltros />
      <br />
      <CustomDataGrid rows={registros} columns={columnsRegistroInventario} />
      <FooterDefault codTela={297}>
        <>
          <CustomButtonNovo
            onClick={handlePrint}
            disabled={!disabledFields || isFetchingTable}
            label="Imprimir"
            width="150px"
            icon={AiOutlinePrinter}
            className="button"
            style={{ backgroundColor: '#808080' }}
          />
        </>
      </FooterDefault>
    </Container>
  );
};

export default RegistroInventario;
