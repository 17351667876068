import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { InputContainer } from './styles';

import { MdOutlineCalendarMonth } from 'react-icons/md';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';

registerLocale('ptBR', ptBR);

interface InputMesAnoProps extends React.HTMLProps<HTMLInputElement> {
  dateValue: Date;
  disabledFields: boolean;
  setDateValue: (val: Date) => void;
  setValue: any;
  isInvalid: boolean;
  setIsInvalid: (val: boolean) => void;
  init: boolean;
}

export const InputMesAno: React.FC<InputMesAnoProps> = ({
  dateValue,
  disabledFields,
  setDateValue,
  setValue,
  isInvalid,
  setIsInvalid,
  init,
  ...rest
}) => {
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  // utilizado como propriedade no css para deixar calendario invisivel
  const [showCalendar, setShowCalendar] = useState<boolean>(true);

  window.onclick = function (event) {
    // usado para não fechar calendario quando navegar entre os anos
    if (event) {
      const elementoClicado = event.target as HTMLElement;

      if (elementoClicado.id === 'open-modal') {
        setOpenCalendar(true);
        return;
      }

      const classesString = elementoClicado.className;
      if (classesString && typeof classesString === 'string') {
        if (
          classesString.includes(
            'react-datepicker__navigation-icon--previous',
          ) ||
          classesString.includes('react-datepicker__navigation-icon--next') ||
          classesString.includes('react-datepicker__navigation') ||
          classesString.includes(
            'react-datepicker__header react-datepicker-year-header',
          )
        ) {
          return;
        }
      }
    }

    handleOnBlurInputDate();

    setOpenCalendar(false);
  };

  // altera o valor do input date e seta valor nas variaveis
  const handleValueInputDoom = (month: number, year: any) => {
    const elementClass = document.getElementsByClassName('input-mes-ano');

    if (elementClass.length > 0) {
      const elemento = elementClass[0];

      if (elemento instanceof HTMLInputElement) {
        const dateVal = `${month}/${year}`;
        elemento.value = dateVal;

        if (month <= 12) {
          setDateValue(new Date(year, month, 0));
        }

        setValue('date', new Date(year, month, 0));
        setOpenCalendar(true);
        setShowCalendar(false);
      }
    }

    handleOnBlurInputDate();
  };

  // valida o input data
  const validInputDate = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    const regexNumber = /^\d*\/?\d*$/;

    handleOnBlurInputDate();
    if (!regexNumber.test(event.target.value)) {
      handleValueInputDoom(
        new Date(dateValue).getMonth() + 1,
        new Date(dateValue).getFullYear(),
      );
      return;
    }

    if (event.target.value.length === 3) {
      if (!event.target.value.includes('/')) {
        if (Number(event.target.value.slice(0, 2)) > 12) {
          handleValueInputDoom(new Date().getMonth() + 1, '');
          return;
        }
        if (Number(event.target.value) !== 1) {
          handleValueInputDoom(
            Number(event.target.value.slice(0, 2)),
            Number(event.target.value.slice(-1)),
          );
          return;
        }
      }
    }

    if (event.target.value.length > 7) {
      handleValueInputDoom(
        new Date(dateValue).getMonth() + 1,
        new Date(dateValue).getFullYear(),
      );

      return;
    }
    if (event.target.value.length >= 6 && event.target.value.includes('/')) {
      const dateSplit: any = event.target.value.split('/');

      const mes_input = dateSplit[0];
      let ano_input = dateSplit[1];

      if (ano_input) {
        if (Number(ano_input) > new Date().getFullYear()) {
          ano_input = String(new Date().getFullYear());

          toast.warning(
            'Pesquisa não pode ser feita com meses e anos futuros!',
          );
        }

        return handleValueInputDoom(mes_input, Number(ano_input));
      }
    }
    if (event.target.value.length === 5 && !event.target.value.includes('/')) {
      let mes;
      let ano;

      if (
        Number(event.target.value.slice(0, 1)) !== 1 &&
        Number(event.target.value.slice(0, 1)) !== 0
      ) {
        mes = event.target.value.slice(0, 1);
        ano = event.target.value.slice(1);

        handleValueInputDoom(Number(mes), ano);
      }
    }
    if (event.target.value.length === 6 && !event.target.value.includes('/')) {
      const mes = event.target.value.slice(0, 2);
      const ano = event.target.value.slice(2);

      handleValueInputDoom(Number(mes), ano);
    }
  };

  const handleOnBlurInputDate = () => {
    const elementClass = document.getElementsByClassName('input-mes-ano');
    if (elementClass.length > 0) {
      // Obtém o primeiro elemento da coleção
      const elemento = elementClass[0];

      if (elemento instanceof HTMLInputElement) {
        if (elemento.value.length < 6) {
          return setIsInvalid(true);
        }
        if (elemento.value.length <= 7) {
          if (elemento.value.includes('/')) {
            const elementoSplit = elemento.value.split('/');

            const mes_input = elementoSplit[0];
            const ano_input = elementoSplit[1];

            if (mes_input.length < 1 || ano_input.length < 4) {
              return setIsInvalid(true);
            }

            if (Number(mes_input) > 12 || Number(mes_input) < 0) {
              return setIsInvalid(true);
            }
          }

          return setIsInvalid(false);
        }
        setIsInvalid(false);
      }
    }
  };

  return (
    <InputContainer showCalendar={showCalendar}>
      <Form.Group>
        <Form.Label style={{ marginBottom: '-10px' }}>Mês</Form.Label>
        <br />
        <div
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          <DatePicker
            selected={dateValue}
            maxDate={new Date()}
            locale="ptBR"
            fixedHeight
            open={openCalendar && !disabledFields}
            disabled={disabledFields}
            onChangeRaw={(event) => {
              if (event.target.value) {
                validInputDate(event);
              } else {
                setIsInvalid(true);
              }
            }}
            className={
              isInvalid && init ? ' input-mes-ano is-invalid' : 'input-mes-ano'
            }
            onChange={(date) => {
              if (date && openCalendar) {
                const selectedDate = Array.isArray(date) ? date[0] : date;
                setDateValue(selectedDate);
                setValue('date', selectedDate);
              }
            }}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            onFocus={() => setOpenCalendar(false)}
          />

          <div className="containerIconCalendar">
            <MdOutlineCalendarMonth
              id="open-modal"
              onClick={() => {
                setOpenCalendar(true);
                setShowCalendar(true);
              }}
            />
          </div>
        </div>
      </Form.Group>
    </InputContainer>
  );
};
