import React, { useEffect, useState } from 'react';

import { TableRow, TableCell } from '@material-ui/core';
import { useRegistroInventario } from '../../RegistroInventarioContext';
import { formatCurrency } from '../CustomDataGrid/function/FormatCurrency';
import { nanoid } from 'nanoid';

export const Totalizadores: React.FC = () => {
  const [totalizador, setTotalizador] = useState<number[]>([]);

  const { registros, columnsRegistroInventario } = useRegistroInventario();

  useEffect(() => {
    const totalizador_temp: any = [];
    registros.forEach((registro: any) => {
      columnsRegistroInventario.forEach((column: any) => {
        if (column.totalizador !== false) {
          if (totalizador_temp[column.field] === undefined) {
            totalizador_temp[column.field] = 0;
          }
          totalizador_temp[column.field] += Number(registro[column.field]);
        }
      });
    });

    setTotalizador(totalizador_temp);
  }, []);

  return (
    <TableRow
      style={{
        display: 'flex',
        position: 'sticky',
        bottom: '-1px',
        backgroundColor: 'rgb(241, 241, 241)',
      }}
      key={1}
    >
      {columnsRegistroInventario.map((column: any) => {
        return (
          <TableCell
            key={nanoid()}
            style={{
              textAlign: column.align,
              backgroundColor:
                column.totalizador !== false
                  ? column.field === 'val_tot_custo_cims_pc'
                    ? '#B3DAB6'
                    : 'rgb(229, 229, 229)'
                  : 'rgb(241, 241, 241)',
              fontWeight: 700,
              width: column.width,
            }}
          >
            {column.totalizador !== false
              ? formatCurrency(totalizador[column.field] || 0, 2)
              : ''}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
