import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .btn.disabled,
  .btn:disabled,
  fieldset:disabled .btn {
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }

  .parceiroContainer input:disabled {
    cursor: not-allowed !important;
  }

  .ButtonLoja .form-check-input:disabled {
    cursor: not-allowed !important;
  }
`;

export const SeparatorLine = styled.p`
  margin-top: 40px;
  margin-bottom: 0px;
  border-bottom: 1px solid #ededed;
  height: 5px;
  width: 99.95%;
`;
