import React, { useContext } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { v4 } from 'uuid';
import { ThemeContext } from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { useRegistroInventario } from '../../RegistroInventarioContext';
import { ColumnSeparator } from './styles';
import { formatCurrency } from './function/FormatCurrency';
import { Totalizadores } from '../Totalizadores';

export interface GridColDefCustomProps {
  field: string;
  headerName?: string | undefined;
  width?: number | string;
  editable?: boolean;
  headerAlign?: string;
  align?: string;
  hideRightSeparator?: boolean;
  format?: string;
  renderCell?: any;
}

interface FormProps {
  rows: any[];
  columns: GridColDefCustomProps[];
  hasTitle?: boolean;
  minHeight?: string;
  maxHeight?: string;
  errorMessage?: string;
  totalizador?: boolean;
}

const CustomDataGrid: React.FC<FormProps> = (props) => {
  const { rows, columns, errorMessage = 'Nenhum registro encontrado' } = props;

  const { colors } = useContext(ThemeContext);

  const { isFetchingTable } = useRegistroInventario();

  function formatType(item: any, type: string | undefined) {
    if (type === 'money') {
      return formatCurrency(item, 2);
    }
    if (type === 'qtd') {
      return formatCurrency(item, 3);
    }

    return item;
  }

  return (
    <Paper>
      <>
        <TableContainer
          style={{
            height: '100%',
            display: 'flex',
            maxHeight: '400px',
            minHeight: '400px',
            flexDirection: 'column',
            justifyContent: `${rows.length > 0 ? 'space-between' : ''}`,
          }}
        >
          <Table stickyHeader>
            <TableHead style={{ position: 'sticky', top: '0', zIndex: '2' }}>
              <TableRow style={{ display: 'flex' }}>
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={v4()}
                      style={{
                        minWidth: `${column.width}`,
                        display: 'flex',
                        justifyContent: column.headerAlign,
                        position: 'relative',
                      }}
                    >
                      {column.headerName}
                      {!column.hideRightSeparator && (
                        <ColumnSeparator>
                          <svg
                            className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M11 19V5h2v14z" />
                          </svg>
                        </ColumnSeparator>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody id="bodyGrid">
              {rows.length > 0 &&
                !isFetchingTable &&
                rows.map((row) => {
                  return (
                    <TableRow
                      id="rowGrid"
                      style={{
                        display: 'flex',
                      }}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={v4()}
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell
                            style={{
                              display: 'flex',
                              minWidth: `${column.width}`,
                              justifyContent: column.align,
                              backgroundColor:
                                column.field === 'val_tot_custo_cims_pc'
                                  ? '#B3DAB6'
                                  : '',
                            }}
                            key={v4()}
                          >
                            {formatType(row[column.field], column.format)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {isFetchingTable && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <ClipLoader color={colors.primary} />
            </div>
          )}

          {!isFetchingTable && rows.length === 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <p>{errorMessage}</p>{' '}
            </div>
          )}

          {!isFetchingTable && rows.length > 0 && <Totalizadores />}
        </TableContainer>
      </>
    </Paper>
  );
};

export default CustomDataGrid;
